import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppInfoService } from '../infrastructure/app-info.service';

export const versionGuard: CanActivateFn = (route, state) => {
  const appInfoService = inject(AppInfoService);
  const router = inject(Router);

  return appInfoService.isVersionSupported$().pipe(
    map((isValid) => {
      // if it is null, do nothing
      if (isValid === false) {
        router.navigate(['/update-required']);
        return false;
      }
      return true;
    })
  );
};

