import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, catchError, of, switchMap, take, from } from 'rxjs';
import { JwtService } from '../infrastructure/jwt.service';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { AccountService } from 'src/app/account/application/account.service';

export const authGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);
  const jwtService = inject(JwtService);
  const router = inject(Router);
  const requiredCapability = route.data['requiredCapability'] as ECapabilities;

  return jwtService.userDataReceived$.pipe(
    take(1),
    switchMap((userDataReceived) => {
      if (userDataReceived) {
        return accountService.hasCapability$(requiredCapability).pipe(
          map((hasCap) => {
            if (hasCap) {
              return true;
            } else {
              handleUnauthorized(router, jwtService);
              return false;
            }
          })
        );
      } else {

        // URL with OPT (i.e. link in an email to a certain freight in the market)
        const token = route.queryParamMap.get('token');

        if (token) {
          return from(accountService.validateToken(token)).pipe(
            switchMap(() => {
              return accountService.hasCapability$(requiredCapability);
            }),
            switchMap((hasCap) => {
              if (hasCap) {
                return of(true);
              } else {
                handleUnauthorized(router, jwtService);
                return of(false);
              }
            }),
            catchError(() => {
              handleUnauthorized(router, jwtService);
              return of(false);
            })
          );
        }

        const accessToken = jwtService.accessToken;
        const refreshToken = jwtService.refreshToken;

        if (!accessToken && !refreshToken) {
          handleUnauthorized(router, jwtService);
          return of(false);
        } else {
          return from(accountService.checkUserAuthentication()).pipe(
            switchMap(() => accountService.hasCapability$(requiredCapability)),
            map((hasCap) => {
              if (hasCap) {
                return true;
              } else {
                handleUnauthorized(router, jwtService);
                return false;
              }
            }),
            catchError(() => {
              handleUnauthorized(router, jwtService);
              return of(false);
            })
          );
        }
      }
    })
  );
};

function handleUnauthorized(router: Router, jwtService: JwtService) {
  console.warn('Unauthorized');
  router.navigate(['/']);
  // TODO review if it is necessary clear tokens
  // jwtService.clearTokens();
}
